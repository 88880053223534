import React from "react";

const Footer = props => {
  return (
    <div className="hero-footer">
      <div className="columns ml-1 mr-1 mb-0 has-text-grey-light">
          <div className="column is-narrow is-one-third">
              <p>
              <strong>Dataly</strong> by the Cornell Tech Dataly Team.
              </p>
          </div>
          <div className="column is-narrow is-one-third"></div>
          <div className="column is-narrow is-one-third has-text-right">
              <a href="mailto:cjb374@cornell.edu">Contact Us</a>
          </div>
      </div>
    </div>
  );
};

export default Footer;