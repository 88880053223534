import ShowArticles from "./ShowArticles";
import { Table } from "react-bulma-components";

const ListArticles = props => {
  console.log('listarticleprops', props)
  return (
    <div className="columns">
      <div className="column">
        <Table className="is-hoverable" striped>
          <thead>
            <tr>
              <th></th>
              <th className="is-size-7 th-font">CREATED AT</th>
              <th className="is-size-7 th-font">ARTICLE STATUS</th>
              <th className="is-size-7 th-font">ARTICLE TITLE</th>
              <th className="is-size-7 th-font">NUMBER OF SUMMARIES</th>
            </tr>
          </thead>
          <tbody>
          {props.articles.length === 0 && props.articleStatus==="complete" && <tr><td>No completed articles yet</td></tr>}
          {props.articles.length === 0 && props.articleStatus==="draft" && <tr><td>No draft articles yet</td></tr>}
          {props.articles.length === 0 && props.articleStatus==="needsFurtherReview" && <tr><td>No articles awaiting review</td></tr>}
          {props.articles.length > 0 &&
            props.articles
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((article, index) => (
                <ShowArticles
                  key={index}
                  articleID={article.id}
                  articleStatus={article.articleStatus}
                  orgID={article.orgID}
                  ownerID={article.ownerID}
                  createdAt={article.createdAt}
                  articleTitle={article.articleTitle}
                  articleText={article.articleText}
                  summaries={article.summaries}
                  keyTerms={article.keyTerms}
                />
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListArticles;