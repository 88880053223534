const SummaryPage = (props) => {
    return (
        <>
        <div className="is-size-7 has-text-grey-light">*Not interactive, just a data preview</div>
        <div className="dropdown is-active mt-3 mb-3 is-fullwidth">
            <div className="dropdown-trigger">
              <button
                className="button is-small"
                aria-haspopup="true"
                aria-controls="dropdown-menu5"
              >
                <span style={{ display: "inline-block" }}>
                  Show where this data originated from.
                  <p className="has-text-grey-light" style={{ display: "inline-block", marginLeft: "5px" }}>
                    Powered by <b>Dataly</b>
                  </p>
                </span>
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div className="dropdown-menu is-fullwidth" id="dropdown-menu5" role="menu">
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <p><strong>Source(s): </strong>{props.source}</p>
                </div>
                <hr className="dropdown-divider" />
                <div className="dropdown-item">
                  <p><strong>Method(s): </strong>{props.methods}</p>
                </div>
                <hr className="dropdown-divider" />
                <div className="dropdown-item">
                  <p><strong>Limitation(s): </strong>{props.limitations}</p>
                </div>
              </div>
            </div>
          </div>
      </>
    )
}

export default SummaryPage;