import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { useState, useEffect } from 'react';
import Home from './pages/Home';
import NavBar from './components/Navbar';
import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';
import AboutPage from './pages/About';
import SignupPage from './pages/Signup';
import ReporterHomePage from './pages/ReporterHome';
import CreateSummaryPage from './pages/CreateSummaryRequest';
import DemoPage from './pages/Demo';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [nameInfo, setName] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
      if (user) {
        setName(user.multiFactor.user.displayName)
        setUser(user)
      } else {
        setName('')
        setUser(null)
      }
      console.log(isSignedIn)
      console.log('userinfo', user)
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const requiresLogin = Component => {
    return isSignedIn ? Component : <Navigate to="/login" />;
  };

  return (
    <Router>
      <NavBar isSignedIn={isSignedIn} user={user} name={nameInfo}/>
    <Routes>
      <Route
        path="/reporter-home"
        element={requiresLogin(<ReporterHomePage user={user}/>)}
      />
      <Route
        path="/login"
        element={
          <LoginPage onLogin={() => {
            setIsSignedIn(true);
            console.log('props.onlogin', isSignedIn)
            // navigate('/reporter-home')
          }}
            isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn}
          />
        }
      />
      <Route exact path="/demo" element={<DemoPage />} />
      <Route exact path="/createsummary" element={<CreateSummaryPage user={user}/>} />
      <Route exact path="/aboutus" element={<AboutPage />} />
      <Route exact path="/signup" element={<SignupPage />} />
      <Route exact path="/logout" element={
        <LogoutPage 
          onLogout={()=> {
            setIsSignedIn(false);
          }}
        />
        } 
        />
      <Route exact path="/" element={<Home />} />
    </Routes>
  </Router>
    );
}

export default App;
