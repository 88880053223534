import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StyledFirebaseAuth } from "react-firebaseui";
import firebase from "firebase/compat";
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';

const config = {
    apiKey: "AIzaSyB7fnwpYJOPJ5cmCTIaXl6xcSzY2ZZQKOA",
    authDomain: "dataly-69353.firebaseapp.com",
    projectId: "dataly-69353",
    storageBucket: "dataly-69353.appspot.com",
    messagingSenderId: "432554053151",
    appId: "1:432554053151:web:64d4d392384e6fc5380f2a",
    measurementId: "G-931N8KDV68"
};

const app = firebase.initializeApp(config);
const analytics = getAnalytics(app)

const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        signInSuccessWithAuthResult: () => false,
        
    },
};

const LoginPage = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.isSignedIn) {
            props.setIsSignedIn(true)
            props.onLogin();
            navigate('/reporter-home')
        }
    }, [props.isSignedIn, props.setIsSignedIn])

    return (
        <>
        <div className="columns mt-5 mb-0 pb-0">
            <div className="column">
                <h1 className="title has-text-centered">Log In or Sign Up:</h1>
            </div>
        </div>
        <div className="columns">
            <div className="column is-centered">
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
        </div>
        </>
    );
};

export default LoginPage;