import React, { useState, useEffect } from "react";
import ListArticles from "./../components/ListArticles";
import { Link } from 'react-router-dom';

const ReporterHomePage = (props) => {
    const [articles, setArticles] = useState([]);
    const [articleStatus, setArticleStatus] = useState("complete");

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(`/api/articles/${articleStatus}`);
        const json_res = await response.json();
        setArticles(json_res.articles)
      };
    
      fetchData();
    }, [articleStatus])

    return (
      <>
      <div className="pl-3 pt-3">
      <Link to="/createsummary" className="button is-danger">
        Start new article +
      </Link>
        <div className="columns pt-3">
          <div className="column tabs is-three-quarters">
            <ul>
              <li className={articleStatus === "complete" ? "is-active" : ""}>
                <a onClick={() => setArticleStatus("complete")}><h1>Completed</h1></a>
              </li>
              <li className={articleStatus === "draft" ? "is-active" : ""}>
                <a onClick={() => setArticleStatus("draft")}><h1>Draft</h1></a>
              </li>
              <li className={articleStatus === "needsFurtherReview" ? "is-active" : ""}>
                <a onClick={() => setArticleStatus("needsFurtherReview")}><h1>Awaiting Further Review</h1></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ListArticles articleStatus={articleStatus} articles={articles} />
      </>
    );
  }

export default ReporterHomePage;