import courtney from "../components/images/courtney.png";
import simona from "../components/images/simona.png";
import lauren from "../components/images/lauren.png";
import eylul from "../components/images/eylul.png";
import sylvie from "../components/images/sylvie.png";
import Footer from "../components/Footer";

const AboutPage = () => {
  return (
    <section className="hero is-fullheight mt-3">
      <div className="columns has-text-centered custom-background">
        <div className="column is-one-fifth"></div>
        <div className="column is-three-fifths">
            <h1 className="title">Meet The Team</h1>
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <figure className="image is-128x128">
                  <img className="is-rounded" src={courtney} alt="Courtney"/>
                </figure>
                <p>Courtney</p>
              </div>
              <div className="column is-narrow has-text-centered">
                <figure className="image is-128x128">
                  <img className="is-rounded" src={simona} alt="Simona"/>
                </figure>
                <p>Simona</p>
              </div>
              <div className="column is-narrow has-text-centered">
                <figure className="image is-128x128">
                  <img className="is-rounded" src={lauren} alt="Lauren"/>
                </figure>
                <p>Lauren</p>
              </div>
              <div className="column is-narrow has-text-centered">
                <figure className="image is-128x128">
                  <img className="is-rounded" src={eylul} alt="Eylul"/>
                </figure>
                <p>Eylul</p>
              </div>
              <div className="column is-narrow has-text-centered">
                <figure className="image is-128x128">
                  <img className="is-rounded" src={sylvie} alt="Sylvie"/>
                </figure>
                <p>Sylvie</p>
              </div>
            </div>
            <p>With skills including Machine Learning, Artificial Intelligence, Natural Language
              Processing, Database Integration, Statistics, and experience working with news, 
              our team is uniquely equipped to handle this challenge.
            </p>
        </div>
      </div>
      <div className="columns has-text-centered">
        <div className="column is-one-fifth"></div>
        <div className="column is-three-fifths">
          <h1 className="title">It all started with a vision to...</h1>
          <p>Create a future of data literate and informed news readers, equipped with the ability to understand and think critically about the data referenced in the media.</p>
        </div>
        <div className="column is-one-fifth"></div>
      </div>
      <div className="columns has-text-centered slate-background has-text-white">
        <div className="column is-one-fifth"></div>
        <div className="column is-three-fifths">
          <h1 className="title has-text-white">Our Theory of Change</h1>
          <p>Research shows that creating pause can lead to shares of more accurate information. Through creating pause in online 
            news reading and giving users the tools they need to think critically about the information they see, we will create 
            increased confidence in understanding data, enhance a readers' ability to critically reason, and foster a healthier online
            ecosystem where users have an expectation of transparency from reporters.
          </p>
        </div>
        <div className="column is-one-fifth"></div>
      </div>
      <Footer />
    </section>
  )
};

export default AboutPage;