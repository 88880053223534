import React, { useRef } from "react";
import { Table } from 'react-bulma-components';
import Footer from "../components/Footer";

const Home = props => {
  return (
    <>
      <section className="section is-large custom-background">
        <h1 class="title slate">Creating a future of data literate news readers</h1>
      </section>
      <section className="section is-medium">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <h1 className="title slate has-text-centered">Compare Plans</h1>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Tier 1</th>
                  <th>Tier 2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Summary Generation</th>
                  <td>150 summaries/month</td>
                  <td>500 summaries/month</td>
                </tr>
                <tr>
                  <th>Cost</th>
                  <td>$89/month</td>
                  <td>$169/month</td>
                </tr>
                <tr>
                  <th>IT Support for Integration</th>
                  <td>
                    <span className="icon has-text-danger">
                      <i className="fas fa-ban"></i>
                    </span>
                  </td>
                  <td>
                    <span className="icon has-text-success">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="columns is-centered has-text-centered">
          <div className="column">
            <p className="is-size-7">Note: all partner companies will be provided with initial instructions for integrating with Dataly, including how to accept Dataly generated data and display it in a frontend using React. Only customers with continuous IT support will be provided with custom integration solutions for the frontend display of Dataly information. See the Demo tab for how our tool may look integrated into your publication.</p>
          </div>
        </div>
      </section>
      <section className="section is-medium slate-background">
        <div className="columns">
          <div className="column">
            <h1 className="title has-text-white has-text-centered">
              Join us in our journey
            </h1>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-one-fourth"></div>
          <div className="column is-one-fourth">
            <div className="buttons is-centered">
              <stripe-buy-button
                buy-button-id="buy_btn_1N4QN2GUvRsB0b9K3UpvvKdl"
                publishable-key="pk_test_51N29DDGUvRsB0b9KDqh0L0ezygWymPnBUiKAD9vgBYWgOJF7ZEoiCmsjzGy9KZlxdftFHP0ux5zlCDsI1UPPbjAx00oB8HVPpI"
              >
              </stripe-buy-button>
            </div>
          </div>
          <div className="column is-one-fourth">
            <div className="buttons is-centered">
              <stripe-buy-button
                buy-button-id="buy_btn_1N4QOaGUvRsB0b9KVztcnII8"
                publishable-key="pk_test_51N29DDGUvRsB0b9KDqh0L0ezygWymPnBUiKAD9vgBYWgOJF7ZEoiCmsjzGy9KZlxdftFHP0ux5zlCDsI1UPPbjAx00oB8HVPpI"
              >
              </stripe-buy-button>
            </div>
          </div>
          <div className="column is-one-fourth"></div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;