import React from "react";
import { Link } from "react-router-dom";
import datalyLogo from "./images/datalyLogo.png";
import firebase from "firebase/compat";
import 'firebase/compat/auth';
import { useState } from "react";

const NavBar = props => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    return (
        <div className="hero-head">
            <nav className="navbar nav-background" role="navigation" aria-label="main navigation">
                <div className="navbar-brand is-flex is-align-items-center">
                    <Link to="/" rel="noreferrer">
                        <img src={datalyLogo} alt="Dataly" width="100" height="50"></img>
                    </Link>
                    <div className={`navbar-burger burger ${isMenuOpen ? "is-active" : ""}`} onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <div id="navBarPages" className={`navbar-menu ${isMenuOpen ? "is-active" : ""}`}>
                    <div className="navbar-start">
                        <Link to="/" className="navbar-item">Home</Link>
                        <Link to="/aboutus" className="navbar-item">About Us</Link>
                        <Link to="/demo" className="navbar-item">Demo</Link>
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">
                            {!props.isSignedIn && <Link to="/login" className="button is-danger">
                                Login
                            </Link>}
                            {props.isSignedIn &&
                            (<><Link to="/reporter-home" className="button has-text-danger">
                                Go to Dashboard
                            </Link>
                            <Link to="/logout" onClick={() => firebase.auth().signOut()} className="button is-danger-light">
                                Log Out
                            </Link></>)}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavBar;