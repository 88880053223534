import { useState, useEffect } from 'react';
import SummaryPage from '../components/Summary';
import { useNavigate } from 'react-router-dom';

const CreateSummaryPage = (props) => {
  const [showBack, setShowBack] = useState(false);
  const [articleTextContents, setArticleTextContents] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [useHighlightedText, setUseHighlightedText] = useState(false);
  const [showSourceBox, setShowSourceBox] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [keyTerm, setKeyTerm] = useState("")
  const [source, setSource] = useState("")
  const [methods, setMethods] = useState("")
  const [limitations, setLimitations] = useState("")
  const [articleTitle, setArticleTitle] = useState("")
  const [articleID, setArticleID] = useState("")
  const [summaryID, setSummaryID] = useState("")
  const userId = props.user.uid
  const navigate = useNavigate();

  useEffect(() => {
    console.log('showSummary has changed:', showSummary);
  }, [showSummary]);

  const handleKeyTermClick = () => {
    console.log("Key term clicked!");
    setShowSourceBox(true);
  };

  const fetchOrganization = async (ownerID) => {
    const response = await fetch('/api/getorganization', {
      method: 'POST', 
      headers:  {
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify({ownerID: ownerID})
    })
    const data = await response.json();
    console.log('userorgdata', data)
  }

  const makeArticle = async (article) => {
    const response = await fetch('/api/createarticle', {
      method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({article: article})
      })
    const data = await response.json()
    return data.articleID
  }

  const makeSummary = async (summary) => {
    const response = await fetch('/api/createsummary', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify({summary: summary})
    })
    const data = await response.json()
    return data.summaryID
  }

  const updateArticleWithSummaryId = (articleResult, summaryResult) => {
    console.log('updateart', articleResult, summaryResult)
    fetch('/api/updatearticlewithsummaryid', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify({articleID: articleResult, summaryID: summaryResult})
    })
    console.log('updatearticleexecuted')
  }

  const saveAsDraft = async event => {
    const ownerID = userId
    console.log('ownerid', ownerID)
    // first fetch owner's organization
    await fetchOrganization(ownerID);
    // create article
    const article = {
      articleText: articleTextContents,
      articleTitle: articleTitle,
      ownerID: ownerID,
      articleStatus: 'draft', 
      keyTerms: keyTerm
    }
    const articlePromise = makeArticle(article);
    const [articleIDResult] = await Promise.all([articlePromise])
    // create summary
    const summary = {
      ownerID: ownerID,
      articleID: articleIDResult, 
      keyTerm: keyTerm, 
      limitations: limitations,
      methods: methods, 
      source: source, 
      summaryStatus: 'draft'
    }
    console.log('summary', summary)
    const summaryPromise = makeSummary(summary);
    const [articleResult, summaryResult] = await Promise.all([articlePromise, summaryPromise]);
    updateArticleWithSummaryId(articleResult, summaryResult);
    navigate('/reporter-home');
  } 
  
  const declineSummary = async event => {
    const ownerID = userId
    // first fetch owner's organization
    await fetchOrganization(ownerID);
    // create article
    const article = {
      articleText: articleTextContents,
      articleTitle: articleTitle,
      ownerID: ownerID,
      articleStatus: 'needsFurtherReview', 
      keyTerms: keyTerm
    }
    const articlePromise = makeArticle(article);
    const [articleIDResult] = await Promise.all([articlePromise])
    // create summary
    const summary = {
      ownerID: ownerID,
      articleID: articleIDResult, 
      keyTerm: keyTerm, 
      limitations: limitations,
      methods: methods, 
      source: source, 
      summaryStatus: 'needsFurtherReview'
    }
    const summaryPromise = makeSummary(summary);
    const [articleResult, summaryResult] = await Promise.all([articlePromise, summaryPromise]);
    updateArticleWithSummaryId(articleResult, summaryResult);
    navigate('/reporter-home');
  } 


  const sendForFurtherReview = async event => {
    const ownerID = userId
    // first fetch owner's organization
    await fetchOrganization(ownerID);
    // create article
    const article = {
      articleText: articleTextContents,
      articleTitle: articleTitle,
      ownerID: ownerID,
      articleStatus: 'needsFurtherReview', 
      keyTerms: keyTerm
    }
    const articlePromise = makeArticle(article);
    const [articleIDResult] = await Promise.all([articlePromise])
    // create summary
    const summary = {
      ownerID: ownerID,
      articleID: articleIDResult, 
      keyTerm: keyTerm, 
      limitations: limitations,
      methods: methods, 
      source: source, 
      summaryStatus: 'needsFurtherReview'
    }
    const summaryPromise = makeSummary(summary);
    const [articleResult, summaryResult] = await Promise.all([articlePromise, summaryPromise]);
    updateArticleWithSummaryId(articleResult, summaryResult);
    navigate('/reporter-home');
  }

  const handleSourceSubmit = event => {
    event.preventDefault()
    const fetchSourceData = async () => {
      const response = await fetch('/api/source', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({source: event.target.source.value})
      })
      const data = await response.json()
      setSource(data.sourceInfo.source)
      setMethods(data.sourceInfo.methods)
      setLimitations(data.sourceInfo.limitations)
    }
    fetchSourceData();
  }

  useEffect(() => {
    if (source && methods && limitations) {
      setShowSummary(true)
    }
  }, [source, methods, limitations])

  const handleSubmit = event => {
    event.preventDefault()
    const fetchData = async () => {
      const response = await fetch('/api/getstats', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({articleTitle: event.target.articleTitle.value, 
          articleText: event.target.articleText.value})
      });
      const data = await response.json()
      console.log('data', data)
      const keyTerm = data.keyTerms[0].keyTerm;
      setKeyTerm(keyTerm)
      const keyTermPosition = data.keyTerms[0].keyTermIndex;
      const articleText = event.target.articleText.value;
      const highlightedArticleText =
        articleText.substring(0, keyTermPosition) +
        `<span id="highlight" class="has-background-warning">${keyTerm}</span>` +
        articleText.substring(keyTermPosition + keyTerm.length);
  
      setHighlightedText(highlightedArticleText); 
      setShowBack(true);
      setArticleTextContents(articleText);
      setArticleTitle(event.target.articleTitle.value);
      setUseHighlightedText(true);
    }
    fetchData()
  }

  const handleBack = event => {
    event.preventDefault();
    setUseHighlightedText(false);
    setShowBack(false);
  };

  useEffect(() => {
    const articleTextArea = document.querySelector('textarea[name="articleText"]');
    if (articleTextArea) {
      setArticleTextContents(articleTextArea.value);
    }
    const highlightedTerm = document.querySelector('#highlight');
    if (highlightedTerm) {
      highlightedTerm.addEventListener('click', handleKeyTermClick);
    }
    return () => {
      if (highlightedTerm) {
        highlightedTerm.removeEventListener('click', handleKeyTermClick);
      }
    };
  }, [useHighlightedText]);  

  const articleText = useHighlightedText ? highlightedText : articleTextContents;

  return (
    <>
      <div className="columns">
        <div className="column mt-3 mb-0 has-text-centered">
          <h1 className='is-size-5'>Create a Dataly Summary</h1>
        </div>
      </div>
      <div className="columns is-half mb-5 pb-5">
        <div className="ml-3 column">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label" htmlFor="name">
                Article Title
              </label>
              <div className="control">
                <input name="articleTitle" className="input" />
              </div>
            </div>

            <div className="field">
              <label className="label mb-0" htmlFor="contents">
                Article Text
              </label>
              {showBack && 
              <p className='is-size-7 mb-3 has-text-light-gray'>
                Click highlighted terms to provide source link and generate summaries
                </p>}
              <div id="articleTextContainer" className="control">
                {useHighlightedText ? (
                  <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
                ) : (
                  <textarea name="articleText" className="textarea" defaultValue={articleTextContents} />
                )}
              </div>
            </div>

            <div className="field has-text-centered">
              {showBack ? (<button className="button is-pulled-left is-dark" onClick={handleBack}>Back</button>):
              (<div className="control is-pulled-right">
                <button type="submit" className="button is-dark">Next</button>
              </div>)}
            </div>
          </form>
        </div>
        <div className="mr-3 mb-3 column">
          <div className="columns">
            <div className="column">
                {showSourceBox && 
              <form onSubmit={handleSourceSubmit}>
                <div className="field">
                  <label className="label" htmlFor="source">
                    Data Source
                  </label>
                  <div className='control'>
                    <input name="source" className='input'></input>
                  </div>
                </div>
                <div className="field mb-0">
                  <div className="control is-pulled-right">
                    <button type="submit" className="button is-dark">Generate Dataly Summary</button>
                  </div>
                </div>
              </form>
              }
            </div>
          </div>
          {showSummary && <hr />}
          <div className="columns is-half is-flex">
          {showSummary && 
            (<>
            <div className="column is-fullheight is-flex-grow mb-3">
              <SummaryPage source={source} methods={methods} limitations={limitations} />
            </div>
            <div className='column'>
              <button onClick={saveAsDraft} className='button is-dark mb-3 is-pulled-right'>Save Summary In Draft</button>
              <button onClick={declineSummary} className='button is-dark mb-3 is-pulled-right'>Decline Summary</button>
              <button onClick={sendForFurtherReview} className='button is-dark is-pulled-right'>Send Summary for Further Review</button>
            </div></>)
          }
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateSummaryPage;
