import { useState, useRef, useEffect } from "react";

const DemoPage = () => {
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [articles, setArticles] = useState([])
  const [summaries, setSummaries] = useState("")
  const [keyTerm, setKeyTerm] = useState("")
  const [sources, setSources] = useState("")
  const [methods, setMethods] = useState("")
  const [limitations, setLimitations] = useState("")
  const [highlightedTextBefore, setHighlightedTextBefore] = useState("")
  const [highlightedTextAfter, setHighlightedTextAfter] = useState("")

  const handleDropdownClick = () => {
    dropdownRef.current.classList.toggle("is-active");
    setDropdownHeight(dropdownContentRef.current.offsetHeight);
  };

  const [isShowingDefinitions, setIsShowingDefinitions] = useState(false);

  const toggleTerms = () => {
    setIsShowingDefinitions(!isShowingDefinitions);
    console.log('dropdownContentRef.current.offsetHeight', dropdownContentRef.current.offsetHeight)
    console.log('dropdownHeight', dropdownHeight)
    setDropdownHeight(dropdownContentRef.current.offsetHeight);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDropdownHeight(dropdownContentRef.current.offsetHeight);
    }, 100);
  
    return () => clearTimeout(timeoutId);
  }, [isShowingDefinitions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/articlebyid/yBRvDn5SktkG8iid1uKz`);
        const json_res = await response.json();
        console.log('json_res', json_res);
        setArticles(json_res.article);
        const text = json_res.article.articleText;
        setKeyTerm(json_res.article.keyTerms[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  useEffect(() => {
    if (keyTerm && articles && articles.articleText) {
      const text = articles.articleText;
      const keyTermIndex = text.indexOf(keyTerm);
      if (keyTermIndex !== -1) {
        const textBeforeKeyTerm = text.substring(0, keyTermIndex + keyTerm.length);
        const textAfterKeyTerm = text.substring(keyTermIndex + keyTerm.length);
        setHighlightedTextBefore(textBeforeKeyTerm.replace(new RegExp(keyTerm, 'g'), `<span class="has-background-warning">${keyTerm}</span>`));
        setHighlightedTextAfter(textAfterKeyTerm.replace(new RegExp(keyTerm, 'g'), `<span class="has-background-warning">${keyTerm}</span>`));
      }
    }
  }, [keyTerm, articles]);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await fetch('/api/summariesbyid/yBRvDn5SktkG8iid1uKz');
        const json_res = await response.json();
        console.log('json_res', json_res);
        setSummaries(json_res.summaries);
        const sources = json_res.summaries.map(summary => summary.sources);
        setSources(sources);
        const methods = json_res.summaries.map(summary => summary.methods);
        setMethods(methods);
        const limitations = json_res.summaries.map(summary => summary.limitations);
        setLimitations(limitations);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSummary();
  }, []);
  
  
  return (
    <>
      <div className="columns mt-3 has-text-centered">
        <div className="column">
          <p className="title">See how this might work:</p>
        </div>
      </div>
      <div className="columns is-centered p-0 m-0">
        <div className="column is-three-fifths" dangerouslySetInnerHTML={{__html: highlightedTextBefore}}>
        </div>
      </div>
      <div className="columns m-0 p-0 is-centered">
        <div className="column is-three-fifths">
          <div className="dropdown" ref={dropdownRef}>
            <div className="dropdown-trigger">
              <button
                className="button is-small"
                aria-haspopup="true"
                aria-controls="dropdown-menu5"
                onClick={handleDropdownClick}
              >
                <span style={{ display: "inline-block" }}>
                  Show where this data originated from.
                  <p className="has-text-grey-light" style={{ display: "inline-block", marginLeft: "5px" }}>
                    Powered by <b>Dataly</b>
                  </p>
                </span>
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div className="dropdown-menu is-fullwidth" id="dropdown-menu5" role="menu">
              <div className="dropdown-content" ref={dropdownContentRef}>
                <div className="dropdown-item">
                  <p><strong>Source(s): </strong>{sources}</p>
                </div>
                <hr className="dropdown-divider" />
                <div className="dropdown-item">
                  <p><strong>Method(s): </strong>{methods}</p>
                </div>
                <hr className="dropdown-divider" />
                <div className="dropdown-item">
                  <p><strong>Limitation(s): </strong>{limitations}</p>
                </div>
                <hr className="dropdown-divider" />
                <div className="dropdown-item">
                  <button onClick={toggleTerms} className="button is-small is-info is-light">Define Summary Terms</button>
                  {isShowingDefinitions && (
                    <div>
                      <hr className="dropdown-divider"></hr>
                      <p><strong>Accuracy:</strong><p>
                        Accuracy refers to the degree to which a summary reflects the
                        information presented in the original text.
                      </p></p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered m-0 p-0">
        <div className="column is-three-fifths">
          <p style={{ transform: `translateY(${dropdownHeight}px)` }} dangerouslySetInnerHTML={{__html: highlightedTextAfter}}>
          </p>
        </div>
      </div> 
      <div className="columns is-centered m-0 p-0">
        <div className="column is-three-fifths">
          <p className="has-text-grey-light">Credit: <a href="https://www.nytimes.com/2018/02/09/technology/facial-recognition-race-artificial-intelligence.html">The New York Times</a></p>
        </div>
      </div>
    </>
  );
};

export default DemoPage;