import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledFirebaseAuth } from "react-firebaseui";
import firebase from "firebase/compat";
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';
import { useEffect } from "react";

const config = {
  apiKey: "AIzaSyB7fnwpYJOPJ5cmCTIaXl6xcSzY2ZZQKOA",
  authDomain: "dataly-69353.firebaseapp.com",
  projectId: "dataly-69353",
  storageBucket: "dataly-69353.appspot.com",
  messagingSenderId: "432554053151",
  appId: "1:432554053151:web:64d4d392384e6fc5380f2a",
  measurementId: "G-931N8KDV68"
};

const app = firebase.initializeApp(config);
const analytics = getAnalytics(app)

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
      signInSuccessWithAuthResult: () => false,
  },
};

const LogoutPage = (props) => {
  const navigate = useNavigate();

  const handleLogoutSuccess = () => {
    props.onLogout(); // update parent component's state to indicate user is signed in
    navigate('/home'); // navigate to the Reporter Home page
  };

  useEffect(() => {
    fetch("/api/logout").then(res => {
      if (res.ok) {
        window.location.href = "/";
      }
    });
  }, []);
  return (
    <div>
      <StyledFirebaseAuth uiConfig={{...uiConfig, callbacks: { signInSuccessWithAuthResult: handleLogoutSuccess }}} firebaseAuth={firebase.auth()} />
    </div>
  );
};

export default LogoutPage;