import { Table } from 'react-bulma-components';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';

const ShowArticles = (props) => {
  const createdAtDate = props.createdAt
  const formattedDate = moment(createdAtDate).format('MMMM Do YYYY, h:mm:ss a');
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [numSummaries, setNumSummaries] = useState();
  const [summaries, setSummaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick = useCallback(async () => {
    setIsExpanded(prevState => {
      console.log(prevState);
      return !prevState;
    });

    if (!isExpanded) {
      await fetchData();
    } 
  }, [isExpanded]);

  const handleDelete = async () => {
    const response = await fetch(`/api/delete/${props.articleID}`);
    const json_res = await response.json();
    window.location.reload();
  }

  const fetchData = async () => {
    setIsLoading(true);
    const response = await fetch(`/api/summariesbyid/${props.articleID}`);
    const json_res = await response.json();
    setSummaries(json_res.summaries);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(`/api/numsummaries/${props.articleID}`);
      const json_res = await response.json();
      setNumSummaries(json_res.numSummaries);
      setIsLoading(false);
    };
    fetchData();
  }, [props.articleID]);

  const subtable = useCallback(() => {
    if (isLoading) {
      return <p>Loading summaries...</p>
    }
    console.log('subtable summaries', summaries)

    if (isExpanded && summaries !== null && summaries.length > 0) {
      console.log('summaries', summaries)
      return (
        <>
          <Table className="table-background" striped>
            <thead>
              <tr>
                <th className="is-size-7 th-font">KEY TERM</th>
                <th className="is-size-7 th-font">SOURCE(S)</th>
                <th className="is-size-7 th-font">SUMMARY STATUS</th>
              </tr>
            </thead>
            <tbody>
              {summaries.length > 1 && summaries.map((summary) => (
                <tr key={summary.id}>
                  <td>{summary.keyTerm}</td>
                  <td>{summary.sources.length > 1 
                    ? <ul>{summary.sources.map(sourceobj => <li key={sourceobj}>{sourceobj}</li>)}</ul> 
                    : <span>{summary.source[0]}</span>}
                  </td>
                  <td>{summary.summaryStatus}</td>
                </tr>
              ))}
              {summaries.length === 1 && 
                (<tr key={summaries[0].id}>
                  <td>{summaries[0].keyTerm}</td>
                  <td>{summaries[0].sources}</td>
                  <td>{summaries[0].summaryStatus}</td>
                </tr>)
              }
            </tbody>
          </Table>
        </>
      )
    } else if (isExpanded) {
      return (
        <p>No summaries here yet.</p>
      )
    }
  })

  if (isLoading) {
    return (
      <tr>
        <td colSpan="2">
          <div className="column is-one-fifth">
            <br />
            <progress className="progress is-small is-danger" max="100">15%</progress>
          </div>
        </td>
      </tr>
    )
  } else {
    return (
      <>
        <tr onClick={handleRowClick} key={props.articleID} className="is-clickable">
          <td>
            <span className="icon-text slate">
              <span className="icon">
                <i className="fas fa-info-circle"></i>
              </span>
              <span>Show Summaries</span>
            </span>
          </td>
          <td>{formattedDate}</td>
          <td>{props.articleStatus}&nbsp;
          {props.articleStatus === "complete" && <span className="icon has-text-success"><i className="fas fa-solid fa-circle"></i></span>}
          {props.articleStatus === "draft" && <span className="icon has-text-warning"><i className="fas fa-solid fa-circle"></i></span>}
          {props.articleStatus === "needsFurtherReview" && <span className="icon has-text-danger"><i className="fas fa-sharp fa-light fa-circle"></i></span>}</td>
          <td>{props.articleTitle}</td>
          <td>{numSummaries}</td>
          <td><button className='button is-danger is-small' onClick={handleDelete}>delete</button></td>
        </tr>
        {isExpanded && 
          <tr>
            <td colSpan="8">
              {subtable()}
            </td>
          </tr>}
      </>
    );
  }
};

export default ShowArticles;